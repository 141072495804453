import { ThemeSelectors } from "@at/design-system-foundation";
import styled from "styled-components";

export const StyledGalleryGridContainer = styled.div<{
  isLeasingPage?: boolean;
}>`
  position: sticky;
  top: 16px;
  display: grid;
  padding: 0;
  margin: 0;
  gap: 0.1rem;
  border-radius: ${({ theme }) => ThemeSelectors.getGroupTwoSpacing(theme)};

  > div:first-child {
    position: relative;
  }

  @media (min-width: 768px) {
    border-radius: ${({ theme }) => ThemeSelectors.getGroupTwoSpacing(theme)};
    gap: 0.5rem;
  }

  ${({ isLeasingPage }) =>
    isLeasingPage
      ? `
      grid-template-columns: 2fr 2fr;
      @media print {
          grid-template-columns: 2fr 2fr;
        }
  
      > div:first-child {
        grid-column: 1 / span 2;
      }
    `
      : `
        grid-template-columns: 2fr 2fr 2fr;
         @media print {
          grid-template-columns: 2fr 2fr 2fr;
        }
      
        > div:first-child {
          grid-column: 1 / span 3;
        }
      `}

  @media print {
    display: grid;
    gap: 0.5rem;
    position: static;
    border-radius: unset;
  }
`;

export const StyledGalleryGridImage = styled.div`
  & .image-gallery-item {
    cursor: pointer;
  }
  &:first-child > .image-gallery-item {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &:nth-child(2) > .image-gallery-item {
    border-bottom-left-radius: 10px;
  }
  &:nth-last-child(2) > .image-gallery-item {
    border-bottom-right-radius: 10px;
  }
`;
