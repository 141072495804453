import { ThemeSelectors } from "@at/design-system-foundation";
import styled from "styled-components";

export const Gallery = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  margin-bottom: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
  @media (min-width: 991px) {
    margin: 0;
  }
  @media print {
    display: flex;
    flex-flow: column;
    height: auto;
    margin: 0;
    position: static;
  }
`;
