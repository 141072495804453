import styled from "styled-components";

export const StyledGallerySliderContainer = styled.div<{
  isLeasingPage?: boolean;
}>`
  overflow: hidden;

  ${({ isLeasingPage }) =>
    isLeasingPage &&
    `
            margin-bottom: 32px;
        `}
`;
