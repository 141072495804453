import { ThemeSelectors } from "@at/design-system-foundation";
import styled from "styled-components";

import { ResetButtonStyles } from "../../utils/styles/buttons.styles";

export const InfoButtonStyled = styled(ResetButtonStyles)`
  font-size: inherit;
  display: inline-block;
  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    fill: ${({ theme }) => ThemeSelectors.getActionColor(theme)};
    &:hover {
      fill: ${({ theme }) => ThemeSelectors.getActionHoverColor(theme)};
    }
  }
`;
