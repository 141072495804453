import { ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled, { css } from "styled-components";

import { InfoButtonStyled } from "../info/info.styles";

const KEY_SPECS_GAP_MEDIUM = "8px";

export const MobilePadding = css`
  @media (max-width: ${BREAKPOINTS.MEDIUM - 1}px) {
    padding-right: ${({ theme }) =>
      ThemeSelectors.getLegacyGridUnit(theme) * 2}px;
    padding-left: ${({ theme }) =>
      ThemeSelectors.getLegacyGridUnit(theme) * 2}px;
  }
`;

export const KeySpecsListItem = styled.li`
  display: flex;
  position: relative;
  flex: 0 0 auto;
  align-items: left;
  flex-direction: column;
  width: 50%;
  min-height: 40px;
  padding: ${({ theme }) => `${ThemeSelectors.getGridUnit(theme)} 0`};
  overflow: hidden;
  border: solid transparent;
  line-height: 1;
  word-break: break-word;

  ${InfoButtonStyled} {
    margin: 0 0
      ${({ theme }) =>
        css`calc(${ThemeSelectors.getGridUnit(
          theme,
        )} / 2) calc(${ThemeSelectors.getGridUnit(theme)} / 2)`};
    display: flex;
  }

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    width: ${100 / 3}%;
    border-width: 0 ${KEY_SPECS_GAP_MEDIUM};
  }

  @media screen and (min-width: ${BREAKPOINTS.WIDE}px) {
    width: ${100 / 4}%;
  }
  @media print {
    width: ${100 / 3}%;
  }
`;
